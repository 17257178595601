* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica, sans-serif;
 }

h1 {
  color:lightseagreen;
  font-size: 64px;
}

h2 {
  color:lightseagreen;
  font-size: 42px;
}

td {
  padding: 5px 20px;
}

a {
  color: black;
  text-decoration: none;
}

nav a {
  margin: 16px;
  color: dimgray;
}

nav a:hover {
  color:black;
  transition: .3s;
 }

nav a.active {
  color:lightseagreen;
  transition: .3s;
}

@keyframes moveGradient {
  0% {
    background-position: 100% 0;
  }
  100% {
    background-position: 0% 0;
  }
}
